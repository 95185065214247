export enum Permission {
	USER = 'USER',
	MANAGE_VIEWER = 'MANAGE_VIEWER',
	SCOREBUG_VIEWER = 'SCOREBUG_VIEWER',
	NEWS_CREATOR = 'NEWS_CREATOR',
	SSO_ACCESS = 'SSO_ACCESS',
	GENIUS_SPORTS_MEMBER = 'GENIUS_SPORTS_MEMBER',
	CONTENT_CONFIRMER = 'CONTENT_CONFIRMER',
	HOMEPAGE_EDITOR = 'HOMEPAGE_EDITOR',
	REFEREE = 'REFEREE',
	HOMEPAGE_VIEWER = 'HOMEPAGE_VIEWER',
	SUPER_ADMIN = 'SUPER_ADMIN',
	NEWS_ADMIN = 'NEWS_ADMIN',
	CONTENT_CREATOR = 'CONTENT_CREATOR',
	USERS_PROFILES_EDITOR = 'USERS_PROFILES_EDITOR',
	ORGANIZATION_MANAGER = 'ORGANIZATION_MANAGER',
	MANAGE_WATCH_NOW = 'MANAGE_WATCH_NOW',
	PICKLEBALL_BRACKETS_ADMIN = 'PICKLEBALL_BRACKETS_ADMIN',
	HOME_PAGE_MARKETING_ADMIN = 'HOME_PAGE_MARKETING_ADMIN',
	PICKLEBALL_TOURNAMENTS_NEWS_ADMIN = 'PICKLEBALL_TOURNAMENTS_NEWS_ADMIN',
	TEAM_LEAGUE_MANAGER = 'TEAM_LEAGUE_MANAGER',
	COMMISSIONER = 'COMMISSIONER',
	MATCH_ODDS_MANAGER = 'MATCH_ODDS_MANAGER',
	MATCH_ODDS_VIEWER = 'MATCH_ODDS_VIEWER',
	PLAYER_ANALYTICS_DATA = 'PLAYER_ANALYTICS_DATA',
	OVERALL_ANALYTICS_DATA = 'OVERALL_ANALYTICS_DATA'
}

export const PB_PERMISSIONS = [
	Permission.MANAGE_VIEWER,
	Permission.HOMEPAGE_EDITOR,
	Permission.HOMEPAGE_VIEWER,
	Permission.NEWS_CREATOR,
	Permission.NEWS_ADMIN,
	Permission.CONTENT_CREATOR,
	Permission.CONTENT_CONFIRMER,
	Permission.USERS_PROFILES_EDITOR,
	Permission.ORGANIZATION_MANAGER,
	Permission.MANAGE_WATCH_NOW,
	Permission.HOME_PAGE_MARKETING_ADMIN,
	Permission.PICKLEBALL_TOURNAMENTS_NEWS_ADMIN,
	Permission.TEAM_LEAGUE_MANAGER,
	Permission.MATCH_ODDS_MANAGER,
	Permission.MATCH_ODDS_VIEWER,
	Permission.PLAYER_ANALYTICS_DATA,
	Permission.OVERALL_ANALYTICS_DATA
];
