'use client';

import { faArrowToTop } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { usePathname } from 'next/navigation';
import { useEffect, useState } from 'react';

interface ScrollToTopProps {
	isTournament?: boolean;
	isTeamLeagues?: boolean;
}

export default function ScrollToTop({ isTournament, isTeamLeagues }: ScrollToTopProps) {
	const [isVisible, setIsVisible] = useState(false);

	const pathname = usePathname();
	const isSearchPage = pathname === '/search';

	const toggleVisibility = () => {
		if (window.scrollY > 300) {
			setIsVisible(true);
		} else {
			setIsVisible(false);
		}
	};

	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		});
	};

	const hasHiddenClass = () => {
		if (!isVisible) return 'hidden';

		if (isTournament && isSearchPage) return 'hidden md:inline-flex';

		return '';
	};

	useEffect(() => {
		window.addEventListener('scroll', toggleVisibility);

		return () => {
			window.removeEventListener('scroll', toggleVisibility);
		};
	}, []);

	return (
		pathname !== '/watch-now' && (
			<div className="fixed bottom-3 left-1/2 z-30 -translate-x-1/2 sm:bottom-3 sm:left-auto sm:right-9 sm:translate-x-0">
				<button
					onClick={scrollToTop}
					className={`ease-[cubic-bezier(0.4,0,0.2,1)] inline-flex h-9 items-center justify-center rounded-lg ${
						isTeamLeagues ? 'bg-orange-500 hover:bg-orange-700' : isTournament ? 'bg-blue-500 hover:bg-blue-700' : 'bg-experiment hover:bg-primary-800'
					} px-4 py-2.5 text-base font-semibold leading-5 text-white transition-all duration-300 ${hasHiddenClass()}`}
				>
					<span>
						<FontAwesomeIcon className="mr-2" icon={faArrowToTop} />
					</span>
					Back to Top
				</button>
			</div>
		)
	);
}
